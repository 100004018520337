

	import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
	import { PropType } from 'vue';
	import { Supplier, TeleinformationDetail, TeleinformationDetailEntity } from '@/modules/entities/types/entities';
	import DeleteModal from '@/modules/entities/components/suppliers/delete-modal.component.vue'

	@Component({
		components: {
			'delete-modal': DeleteModal,
		}
	})
	export default class SupplierTeleinformationsComponent extends Vue {
		@PropSync('teleinformations', { type: Array as PropType<Array<TeleinformationDetail>>, default: () => [] }) teleinformationsSync!: TeleinformationDetail[];
		@PropSync('currentlyEditedTeleinformationType', { type: String, required: true }) currentlyEditedTeleinformationTypeSync!: string;
		@PropSync('currentlyEditedTeleinformationIndex', { type: Number, required: true }) currentlyEditedTeleinformationIndexSync!: number;
		@Prop({ type: Object as PropType<Supplier>, required: true }) vuexSupplier!: Supplier;

		state: {
			deleteTeleinformation: boolean
		} = {
			deleteTeleinformation: false
		}
		content: {
			deleteTeleinformation: TeleinformationDetail
		} = {
			deleteTeleinformation: new TeleinformationDetailEntity()
		}

		editTeleinformation(idx: number, type: string): void {
			this.currentlyEditedTeleinformationTypeSync = type;
			this.currentlyEditedTeleinformationIndexSync = idx;
		}

		onClickDeleteTeleinformation(teleinformation: TeleinformationDetail) {
			this.state.deleteTeleinformation = true
			this.content.deleteTeleinformation = teleinformation
		}

		onClickMarkIsMainContactInformation(teleinformation: TeleinformationDetail) {
			this.teleinformationsSync.forEach(item => item.isMainContactInformation = false)
			teleinformation.isMainContactInformation = true
			this.$emit('editSupplierThenMutateState', 'teleinformation')
		}

		displayDeleteTeleinformationConfirmDialog(teleinformation: TeleinformationDetail): boolean {
			return this.state.deleteTeleinformation && this.content.deleteTeleinformation.id === teleinformation.id;
		}
	}
