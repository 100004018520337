
	import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';

	@Component
	export default class DeleteModalComponent extends Vue {

		// vuex actions
		// vuex getters

		// props
		@Prop({ type: Object }) value!: any;
		@Prop({ type: Boolean, default: true }) persistent!: boolean;
		@PropSync('showModal', { type: Boolean }) showModalSync!: boolean;

		// refs
		@Ref('confirmDelete') readonly confirmDelete!: Vue
		
		// vars
		i_value: any = this.clone(this.value)

		// handlers
		onClickCancel(e: any): void {
			this.$emit('cancel')
		}
		onClickConfirm(e: any): void {
			this.$emit('delete')
		}

		// methods
		clone(value: any) {
			if(value) return JSON.parse(JSON.stringify(value))
			return null
		}

		// getters 

		// watchers
		@Watch('showModal')
		onWatchValue(value: any) {
			this.$nextTick(() => {
				setTimeout(() => (this.confirmDelete.$el as HTMLButtonElement).focus())
			})
		}
		
		// lifecycle
		mounted(): void {
			//
		}

		created(): void {
			//
		}

		destroyed(): void {
			//
		}
	}
